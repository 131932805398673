import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";

import BreakpointAwareAsset from "~/components/common/breakpoint-aware-asset";
import Container from "~/components/common/container";
import EscapeNewLine from "~/components/common/escape-new-line";
import Grid from "~/components/common/grid";
import HighlightedText from "~/components/common/highlighted-text";
import MediaAsset from "~/components/common/media-asset";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Countdown from "../../common/countdown";
import EditorialLink from "../editorial-link/editorial-link";
import styles from "./hero-slide.module.scss";

type Props = {
  entry: Entry<unknown>;
  noLazy?: boolean;
};

export default function HeroSlide(props: Props) {
  const router = useRouter();
  if (!isActiveEntry(props.entry)) return null;

  assertEditorialType<Editorials.HeroSlide>(props.entry, "heroSlide");

  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  return (
    <div className={styles.container}>
      {props.entry.fields.link && isActiveEntry(props.entry.fields.link) ? (
        <Link
          href={textUtils.sanitizeContentfulUrl(props.entry.fields.link, router)}
          target={props.entry.fields.link.fields.openOnANewTab ? "_blank" : undefined}
        >
          <BreakpointAwareAsset
            entry={props.entry.fields.media}
            className={classNames(styles.image, props.entry.fields.moveMediaDown ? styles.moveMediaDown : undefined)}
            fill
            videoConfig={{ muted: true, loop: true, autoPlay: true, controls: false, playsInline: true }}
            noLazy={props.noLazy}
          />
        </Link>
      ) : (
        <BreakpointAwareAsset
          entry={props.entry.fields.media}
          className={classNames(styles.image, props.entry.fields.moveMediaDown ? styles.moveMediaDown : undefined)}
          fill
          videoConfig={{ muted: true, loop: true, autoPlay: true, controls: false, playsInline: true }}
          noLazy={props.noLazy}
        />
      )}
      <Container className={styles.h100}>
        <Grid className={styles.h100}>
          {/* panel could be moved into a dedicated component */}
          <div className={styles.panel}>
            <h2 {...inspectorMode?.getProps("title")} className={styles.title}>
              <HighlightedText text={props.entry.fields.title} />
            </h2>
            <div className={styles.descriptionWrapper}>
              {props.entry.fields.description ? (
                <p {...inspectorMode?.getProps("description")} className={styles.description}>
                  <EscapeNewLine text={props.entry.fields.description} />
                </p>
              ) : null}
              {props.entry.fields.logo ? (
                <div className={styles.mediaWrapper}>
                  <MediaAsset entry={props.entry.fields.logo} />
                </div>
              ) : null}
            </div>
            {props.entry.fields.link ? <EditorialLink positionArrow="end" entry={props.entry.fields.link} /> : null}
            {props.entry.fields.countdown ? (
              <Countdown
                entry={props.entry}
                date={props.entry.fields.countdown}
                customStyles={{
                  containerCountdown: styles.containerCountdown,
                  wrapperCountdown: styles.wrapperCountdown,
                  labelCountdown: styles.labelCountdown,
                  numberCountdown: styles.numberCountdown,
                }}
              />
            ) : null}
          </div>
        </Grid>
      </Container>
    </div>
  );
}
