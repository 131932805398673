import "swiper/css";

import classNames from "classnames";
import { Entry } from "contentful";
import { useEffect, useRef, useState } from "react";
import { Autoplay, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper/types/swiper-class";

import Container from "~/components/common/container";
import Icon from "~/components/common/icon";
import { useTextDir } from "~/contexts/text-dir";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import styles from "./hero.module.scss";
import HeroSlide from "./hero-slide";

type Props = {
  entry: Entry<unknown>;
  enabled?: boolean;
};

export default function Hero({ entry, enabled = true }: Props) {
  const dir = useTextDir();

  const swiperRef = useRef<SwiperClass>();
  const [autoplayStatus, setAutoplayStatus] = useState(true);
  const [realIndex, setRealIndex] = useState(0);

  useEffect(() => {
    if (dir && swiperRef.current) {
      swiperRef.current.changeLanguageDirection(dir);
    }
  }, [dir]);

  useEffect(() => {
    if (enabled) {
      swiperRef.current?.enable();
    } else {
      swiperRef.current?.disable();
    }
  }, [enabled]);

  if (!isActiveEntry(entry)) return null;

  assertEditorialType<Editorials.Hero>(entry, "hero");

  return (
    <div className={classNames("beacon-hero", styles.container)}>
      <Swiper
        modules={[Autoplay, Keyboard]}
        keyboard
        loop
        autoplay={{ delay: entry.fields.duration * 1000 }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        onAutoplayStart={(swiper) => setAutoplayStatus(swiper.autoplay.running)}
        onAutoplayStop={(swiper) => setAutoplayStatus(swiper.autoplay.running)}
        onSlideChange={(swiper) => setRealIndex(swiper.realIndex)}
        enabled={enabled}
        speed={Constants.SWIPER_TRANSITION_DURATION}
      >
        {entry.fields.slides.filter(isActiveEntry).map((slide, index) => (
          <SwiperSlide key={slide.sys.id}>
            <HeroSlide entry={slide} noLazy={index == 0} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Container className={styles.innerContainer}>
        <div
          className={classNames(styles.carouselBullets, { [styles.autoplayBullets]: autoplayStatus })}
          style={{ "--autoplay-duration": `${entry.fields.duration * 1000}ms` } as React.CSSProperties}
        >
          {entry.fields.slides.filter(isActiveEntry).map((slide, i) => (
            <button
              key={slide.sys.id}
              className={classNames(
                styles.bullet,
                realIndex == i ? (autoplayStatus ? styles.bulletAutoplayActive : styles.bulletActive) : ""
              )}
              onClick={() => swiperRef.current?.slideToLoop(i)}
            />
          ))}
        </div>
        <button
          className={styles.pause}
          onClick={() => swiperRef.current?.autoplay?.[autoplayStatus ? "stop" : "start"]()}
        >
          <Icon name={autoplayStatus ? "pause" : "play"} color="black" />
        </button>
      </Container>
    </div>
  );
}
