import { Entry } from "contentful";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { Duration } from "ts-luxon";

import contentfulUtils from "~/utils/contentful-utils";

type CustomStyles = {
  containerCountdown?: string;
  wrapperCountdown?: string;
  labelCountdown?: string;
  numberCountdown?: string;
};

type Props = {
  entry: Entry<unknown>;
  date: Date;
  customStyles?: CustomStyles;
};

export default function Countdown({ entry, date, customStyles }: Props) {
  const t = useTranslations();
  const countDownDate = new Date(date).getTime() + 60 * 1000;
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());
  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDownDate - new Date().getTime() > 0) setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const getReturnValues = (countDown: number) => {
    const duration = Duration.fromMillis(countDown).shiftTo("days", "hours", "minutes", "seconds").toObject();

    return { days: duration.days, hours: duration.hours, minutes: duration.minutes };
  };

  const { days, hours, minutes } = getReturnValues(countDown);
  const timeExpired = (days ?? 0) + (hours ?? 0) + (minutes ?? 0) <= 0;
  return (
    <>
      {!timeExpired ? (
        <div className={customStyles?.containerCountdown} {...inspectorMode?.getProps("countdown")}>
          <div className={customStyles?.wrapperCountdown}>
            <span className={customStyles?.numberCountdown}>{days}</span>
            <span className={customStyles?.labelCountdown}>{t("generic.days")}</span>
          </div>
          <div className={customStyles?.wrapperCountdown}>
            <span className={customStyles?.numberCountdown}>{hours}</span>
            <span className={customStyles?.labelCountdown}>{t("generic.hours")}</span>
          </div>
          <div className={customStyles?.wrapperCountdown}>
            <span className={customStyles?.numberCountdown}>{minutes}</span>
            <span className={customStyles?.labelCountdown}>{t("generic.minutes")}</span>
          </div>
        </div>
      ) : null}
    </>
  );
}
